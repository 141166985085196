import React from 'react';
/* new */
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { PageHeader } from 'src/layouts/PageHeader';
import styled, { css } from 'styled-components';
const Container = styled.section`
  ${({ customPadding }) => css`
    padding: ${customPadding ? customPadding : '0 1rem 2rem'};
  `}

  ${({ customTextColor }) =>
    customTextColor &&
    css`
      color: ${customTextColor};
      --alternative-heading-color: ${customTextColor};
      --primary-color: ${customTextColor};
    `}

  ${({ alternative, primary }) =>
    (alternative || primary) &&
    css`
      color: white;
      --alternative-heading-color: white;
      --primary-color: white;
      --secondary-color: #343434;
    `}

  ${({ sloped }) =>
    sloped &&
    css`
      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        margin-bottom: -16rem;
        padding-bottom: 16rem;
      }
    `}
`;
const BackgroundContainer = styled.div`
  position: absolute;
  background-color: #1d005a;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 4rem;

  ${({ fluidBackgroundImg, backgroundImage }) =>
    fluidBackgroundImg && backgroundImage
      ? css`
        background-image: url('${backgroundImage.url}');
        background-size: cover;
      `
      : !fluidBackgroundImg && backgroundImage
      ? css`
        @media (min-width: 768px) {
          ${({backgroundImage}) => {
            return backgroundImage && css`
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url('${backgroundImage.url}');
            `
          }}
        }
      `
      : ''
  }

  @media (min-width: 996px) {
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0 0;
  text-align: center;
  margin: 0 auto;
  max-width: ${({ width, theme }) =>
    width === 'xlarge' ? theme.xlargeContentWidth : theme.largeContentWidth};

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    flex-direction: row;
    align-items: center;

    ${({ alternative, centered }) =>
      alternative
        ? css`
            flex-direction: column;
            text-align: center;
          `
        : css`
            text-align: ${centered ? 'center' : 'left'};
          `}
  }
`;
const Content = styled.div`
  flex: 1.1;
  margin-bottom: 2rem;

  h5 {
    color: inherit;
  }
`;
const HeroImageContainer = styled.div`
  position: relative;
  text-align: center;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    margin-left: 2rem;
  }

  ${({ alternative }) =>
    alternative &&
    css`
      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    `}
  ${({ wavey }) =>
    wavey &&
    css`
      @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
        margin-bottom: -2rem;
      }
    `};
`;
const SubtitleContainer = styled.div`
  hyphens: none;
  max-width: ${({ theme, subtitleWidth }) => {
    switch (subtitleWidth) {
      case 'small':
        return theme.smallContentWidth;

      case 'medium':
        return theme.mediumContentWidth;

      case 'large':
        return theme.largeContentWidth;

      case 'xlarge':
        return theme.xlargeContentWidth;

      case 'full':
        return '100%';

      default:
        return theme.largeContentWidth;
    }
  }};
  ${({ centered }) =>
    centered &&
    css`
      margin: 0 auto;
    `}
`;
export const Hero = ({
  width = 'large',
  centered,
  title,
  subtitle,
  category,
  actions,
  image,
  alternative,
  backgroundImage,
  fluidBackgroundImg,
  sloped,
  wavey,
  primary,
  dark,
  subtitleWidth = 'small',
  brandColor,
  headerColor,
  footerBackgroundColor,
  footerTextColor,
  footerHeaderColor,
  customTextColor,
  customPadding,
  content,
  contentNode
}) => {
  return (
    <PageHeader
      {...{
        alternative,
        primary,
        dark,
        brandColor,
        headerColor,
        footerBackgroundColor,
        footerTextColor,
        footerHeaderColor
      }}
    >
      <Container
        customPadding={customPadding}
        customTextColor={customTextColor}
        alternative={alternative}
        sloped={sloped}
        primary={primary}
      >
        <BackgroundContainer
          fluidBackgroundImg={fluidBackgroundImg}
          backgroundImage={backgroundImage} />

        <Wrapper centered={centered && !image} width={width} alternative={alternative} image={image}>
          <Content>
            {!!category && <h5>{category}</h5>}
            {title && <h1 style={{ whiteSpace: 'pre-wrap' }}>{title.replace(/\\n/g, "\n")}</h1>}
            {content.length > 0 && (
              <MDXProvider components={MDXComponents}>
                <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
              </MDXProvider>
            )}
          </Content>
          {!!image && (
            <HeroImageContainer alternative={alternative} wavey={wavey}>
              {image}
            </HeroImageContainer>
          )}
        </Wrapper>
      </Container>
    </PageHeader>
  );
};
